/** @format */

import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Nav } from "react-bootstrap";

const MainNav = () => {
  return (
    <Container>
      <Row className="main-nav-row">
        <Col>
          <Nav activeKey="/home">
            <Nav.Item>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/innovations" className="nav-link">
                Innovations
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/about-us" className="nav-link">
                About Us
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/contact-us" className="nav-link">
                Contact Us
              </Link>
            </Nav.Item>
            <Nav.Item className="justify-content-end">
              <span className="nav-phone">
                Call Us Toll Free:{" "}
                <a href="tel:018003232594" style={{ color: "#5c5f62" }}>
                  (800) 323-2594
                </a>
              </span>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};
export default MainNav;
