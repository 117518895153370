import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function Footer({ children }) {
  return (
    <Container fluid className="footer-container">
      <Row className="justify-content-center">
        <Col>{children}</Col>
      </Row>
    </Container>
  );
}
