/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/style.scss";
import * as React from "react";
import Amplify from "aws-amplify";
import awsExports from "./src/aws-exports";
import Layout from "./src/components/layouts/home";
Amplify.configure(awsExports);
export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};
