import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Nav from "react-bootstrap/Nav";

export const CategoriesNav = () => {
  const data = useStaticQuery(graphql`
    {
      allShopifyCollection(
        filter: { products: { elemMatch: { id: { ne: "" } } } }
        sort: { order: ASC, fields: title }
      ) {
        nodes {
          title
          handle
        }
        totalCount
      }
    }
  `);

  const categories = data.allShopifyCollection.nodes.map((category, index) => {
    category.sortOrder = index;
    category.linkTo = "/category/" + category.handle;
    category.className = "link-no-style dropdown-item";
    return category;
  });

  return (
    <Nav defaultActiveKey="/products" className="flex-column categories-nav">
      <Link
        to="/products"
        className="nav-link allprods-nav-link"
        activeClassName="active"
      >
        Products
      </Link>
      {categories.map(category => (
        <Link
          to={category.linkTo}
          className="nav-link sub-cat-link"
          activeClassName="active"
          key={category.handle}
        >
          {category.title}
        </Link>
      ))}
    </Nav>
  );
};
