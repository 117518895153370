import * as React from "react";
import { useState, useEffect} from "react";
import { Link } from "gatsby";
import { Header } from "../containers/header";

import { Footer } from "../containers/footer";
import { Leftcol } from "../containers/leftcol";
import { Seo } from "../helpers/seo";
import { CategoriesNav } from "../compositions/categories-nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import bioBrigadeImg from "../../images/bio-brigade.jpeg";
import esImg from "../../images/ES-Logo.jpeg";

export default function Layout({ children, location }) {
  const [showChildren, setShowChildren] = useState(false);
  const isVideoVisibleHere = location ? ((location?.pathname === '/' ) || (location?.pathname === '/innovations' ) || (location?.pathname === '/about-us' ) || (location?.pathname === '/contact-us' )) : false;
  useEffect(() => {
    setTimeout(() => {
      setShowChildren(true);
    }, 1500);
  }, []);
  return (
    <div>
      <Seo />
      <Header>
         {isVideoVisibleHere && (
        <Container id="home-header-video-container">
          <Row>
            <video id="home-header-video" autoPlay muted loop>
              <source src="https://assets.unitedlabsmdsupply.com/web/mdsupply-headervid1/MDSupply_website_header1-720p.mp4" type="video/mp4">
              </source>
              <source src="https://assets.unitedlabsmdsupply.com/web/mdsupply-headervid1/MDSupply_website_header1-720p.webm" type="video/webm">
              </source>
            </video>
          </Row>
          </Container>
         )}
      </Header>
      <Container>
        <Row>
          <Leftcol>
            <CategoriesNav />
            <hr />
            <Image fluid src={bioBrigadeImg} />
            <hr />
            <Image fluid src={esImg} />
          </Leftcol>
          <Col className="home-main-col">
              {showChildren && children } 
          </Col>
        </Row>
      </Container>
      <Footer>
        <p>
          United Laboratories is a leading manufacturer and distributor of
          maintenance supplies and chemicals for&nbsp;medical facilities.&nbsp;
        </p>
        <p>
          unitedlabsmdsupply.com represents the medical supply division of
          United Laboratories, Inc.
        </p>
        <p>
          <a href="/sitemap/sitemap.xml">View Sitemap</a>
          &nbsp;- <Link to="/contact-us">Contact Info</Link>
          &nbsp;- <Link to="/about-us">Company Info</Link>
        </p>
        <p>
          © 2009-2022 United Laboratories ● 320 37th Avenue ● St. Charles, IL
          60174 ● ph: (800) 323-2594
        </p>
      </Footer>
    </div>
  );
}
