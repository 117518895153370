import * as React from "react";
import Col from "react-bootstrap/Col";

export function Leftcol({ children }) {
  return (
    <Col xs={12} sm={5} md={4} lg={3} xl={3} xxl={3} className="leftcol">
      {children}
    </Col>
  );
}
