import * as React from "react";
import MainNav from "../compositions/main-nav";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import logo from "../../images/logo-primary.gif";

export function Header({ children }) {
  return (
    <Container fluid className="header-container">
      <Row>
        <Col xs={3} id="logo-col">
          <Link to="/">
            <Image fluid src={logo} alt="Logo" />
          </Link>
        </Col>
        <Col xs={12} sm={12} md={12} lg={9} id="nav-col">
          <MainNav />
        </Col>
      </Row>
      {children}
    </Container>
  );
}
